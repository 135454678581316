<template>
  <div>
    <b-row>
      <b-col>
        <statistic-card-vertical
            icon="UserCheckIcon"
            :statistic="getFormat(rounded(calculateData.calculate_audience.total))"
            :statistic-title="$t('general.audience')"
            color="success"
          />
      </b-col>
      <b-col>
        <statistic-card-vertical
            icon="UserXIcon"
            :statistic="getFormat(rounded(calculateData.calculate_fake.total))"
            :statistic-title="$t('general.fake')"
            color="warning"
          />
      </b-col>
      <b-col>
        <statistic-card-vertical
            icon="UsersIcon"
            :statistic="getFormat(calculateData.calculate_followers.total)"
            :statistic-title="$t('general.followers')"
            color="info"
          />
      </b-col>
      <b-col>
        <statistic-card-vertical
            icon="ThumbsUpIcon"
            :statistic="getFormat(calculateData.calculate_influence_level)"
            :statistic-title="$t('general.influence')"
            color="primary"
          />
      </b-col>
    </b-row>

    <b-row>
      <b-col v-if="audience_series.length > 1" cols="12" class="col-sm-6 col-md-4">
        <pie-chart
          :labels="audience_labels"
          :series="audience_series"
          :colors="audience_colors"
          :title="$t('general.audience')"
          :chart_total="getFormat(rounded(calculateData.calculate_audience.total))"
          :key="change_language"
          />
      </b-col>

      <b-col v-if="followers_series.length > 1" cols="12" class="col-sm-6 col-md-4">
        <pie-chart
          :labels="followers_labels"
          :series="followers_series"
          :colors="followers_colors"
          :title="$t('general.followers')"
          :chart_total="getFormat(calculateData.calculate_followers.total)"
          :key="change_language"
          />
      </b-col>

      <b-col v-if="type_audience_series.length > 1" cols="12" class="col-sm-6 col-md-4">
        <pie-chart
          :labels="type_audience_labels"
          :series="type_audience_series"
          :colors="type_audience_colors"
          :title="$t('general.type_audience')"
          :chart_total="getFormat(rounded(type_audience_total))"
          :key="change_language"
          />
      </b-col>
    </b-row>

    <Prices v-if="role !== 'espejo'" :prices="prices" class="mt-3"/>
    <Collaborations :collaborations="collaborations" class="mt-3"/>
    <SimilarAccounts v-if="similar_accounts.length > 0" :similar_accounts="similar_accounts" class="mt-3" :key="key_similar"/>
  </div>
</template>
<script>
import {BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import Prices from './Prices.vue'
import Collaborations from './Collaborations.vue'
import SimilarAccounts from './SimilarAccounts.vue'
import others from "@/services/others";
import profile from "@/services/profile";
import pieChart from './PieChart.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
    Prices,
    Collaborations,
    SimilarAccounts,
    pieChart
  },
  props: {
    calculateData: {
      type: Object,
      required: true
    },
    riskData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPrices: false,
      showInterest: false,
      showCollabs: false,
      change_language: false,
      key_similar: false,
      collaborations: [],
      prices: [],
      similar_accounts: [],
      type_audience_labels: [],
      type_audience_series: [],
      type_audience_colors: [],
      type_audience_total: 0,
      audience_labels: [],
      audience_series: [],
      audience_colors: [],
      followers_labels: [],
      followers_series: [],
      followers_colors: [],
      network_colors: {
        'twitter': '#40AAEC',
        'instagram': '#CD4D4D',
        'facebook': '#1A66F3',
        'youtube': '#CF302B',
        'tiktok': '#EC3F79',
        'blog': '#F0A23F',
        'linkedin': '#346297',
        'spotify': '#64D862',
        'pinterest': '#E73631',
        'twitch': '#924DF7',
        'snapchat': '#F5D90D'
      },
      colors: [
        '#40AAEC',
        '#CD4D4D',
        '#1A66F3',
        '#CF302B',
        '#EC3F79',
        '#F0A23F',
        '#346297',
        '#64D862',
        '#E73631',
        '#924DF7',
        '#F5D90D'
      ],
      role: 'brand'
    }
  },
  beforeCreate() {
    others.getBriefcase(this.$route.params.network.toLowerCase(), this.$route.params.alias).then((response) => {
      this.collaborations = response.results;
    });

    others.getPricing(this.$route.params.network.toLowerCase(), this.$route.params.alias).then((response) => {
      this.prices = response.results
    })

    profile.getSimilarAccounts(this.$route.params.network.toLowerCase(), this.$route.params.alias).then(response => {
      const similar_accounts = this.$route.params.network.toLowerCase() === 'youtube' ? Object.entries(response.channels) : Object.entries(response.data.bloggers);
      similar_accounts.forEach((influencer) => {
        const similar = {
          img: influencer[1].basic.avatar_url,
          fullname: influencer[1].basic.fullname ? influencer[1].basic.fullname : influencer[1].basic.title,
          username: influencer[1].basic.username && influencer[1].basic.username !== '' ? influencer[1].basic.username : influencer[1].basic.id,
          followers: influencer[1].metrics.subscribers_count.value,
          last_media: this.$route.params.network.toLowerCase() === 'youtube' ? influencer[1].features.last_media.data.thumbnail : null,
        }
        if (this.similar_accounts.length < 4) this.similar_accounts.push(similar);
      });
      this.key_similar = !this.key_similar;
    })
      .catch(() => {
        this.similar_accounts = [];
        this.key_similar = !this.key_similar;
      });
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.groups[0].name

    if (this.calculateData.calculate_audience && this.calculateData.calculate_audience.data) {
      this.calculateData.calculate_audience.data.forEach((item) => {
        this.audience_labels.push(`${item.network} (${item.username})`);
        this.audience_series.push(Math.round(item.audience));
        this.audience_colors.push(this.network_colors[item.network]);
      });
    }

    if (this.calculateData.calculate_followers && this.calculateData.calculate_followers.data) {
      this.calculateData.calculate_followers.data.forEach((item) => {
        this.followers_labels.push(`${item.network} (${item.username})`);
        this.followers_series.push(Math.round(item.followers));
        this.followers_colors.push(this.network_colors[item.network]);
      })
    }

    if (this.calculateData.info && this.calculateData.info.audience_ethnicity) {
      this.calculateData.info.audience_ethnicity.forEach((item, index) => {
        this.type_audience_labels.push(this.$t(item.name));
        this.type_audience_series.push(item.value);
        this.type_audience_colors.push(this.colors[index]);
        this.type_audience_total += item.value;
      });

      this.change_language = !this.change_language;
    }
  },
  methods: {
    rounded (num) {
      if (num === undefined) return Math.round(0)
      return Math.round(num)
    },
    getFormat (num) {
      if (num === undefined) return '0'
      if (num >= 1000000) return `${this.rounded(num / 1000000)}M`
      if (num >= 1000) return `${this.rounded(num / 1000)}K`
      return num.toString()
    },
    handlePrices () {
      this.showPrices = !this.showPrices;
      this.showInterest = false;
      this.showCollabs = false;
    },
    handleInterest () {
      this.showPrices = false;
      this.showInterest = !this.showInterest;
      this.showCollabs = false;
    },
    handleCollabs () {
      this.showPrices = false;
      this.showInterest = false;
      this.showCollabs = !this.showCollabs;
    },
  },
  watch: {
    '$i18n.locale'() {
      if (this.calculateData.info && this.calculateData.info.audience_ethnicity) {
        this.type_audience_labels = []
        this.type_audience_series = []
        this.type_audience_colors = []
        this.type_audience_total = 0
        this.calculateData.info.audience_ethnicity.forEach((item, index) => {
          this.type_audience_labels.push(this.$t(item.name));
          this.type_audience_series.push(item.value);
          this.type_audience_colors.push(this.colors[index]);
          this.type_audience_total += item.value;
        });
      }
      this.change_language = !this.change_language;
    }
  }
}
</script>
<style>
  .cardPricing{
    height: 180px;
  }

  @media(max-width:1358px){
    .cardPricing{
    height: 235px;
  }
  }

  @media(max-width:1199px){
    .cardPricing{
    height: 180px;
    }
  }
</style>
