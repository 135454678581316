<template>
  <div v-if="similar_accounts.length > 0">
    <b-card-title>{{ $t('similar_accounts') }}</b-card-title>
    <b-row class="match-height">
      <b-col cols="12" class="col-sm-6 col-md-4 col-lg-3" v-for="(similar, index) in similar_accounts" :key="index">
        <b-card class="text-center" footer-class="without-border">
          <b-avatar :src="images[index]" size="6rem"></b-avatar>
          <b-link :href="utils.getURL($route.params.network.toLowerCase(), similar.username, true)" target="blank">
            <b-card-title class="mt-2">{{similar.username}}</b-card-title>
          </b-link>
          <b-card-sub-title>{{similar.fullname}}</b-card-sub-title>
          <b-card-text class="mt-4">{{utils.getFormat(similar.followers)}} {{$t('campaigns.followers')}}</b-card-text>
          
          <template #footer>
            <b-link :href="urlProfile(similar.username)" target="blank">
              <b-button variant="primary">{{ $t('go-porfile') }}</b-button>
            </b-link>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BAvatar, BCardTitle, BCardSubTitle, BCardText, BLink, BButton } from 'bootstrap-vue'
import utils from '@/libs/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BLink,
    BButton
  },
  props: {
    similar_accounts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      utils,
      images: []
    }
  },
  mounted() {
    const self = this
    this.similar_accounts.forEach((influencer) => {
      utils.urlImagetoBase64(influencer.img, function (result) {
        self.images.push(result)
      });
    })
  },
  methods: {
    urlProfile(username) {
      if (this.$route.params.network.toLowerCase() === 'tiktok') return `/perfil/instagram/${username}`;
      
      return `/perfil/${this.$route.params.network.toLowerCase()}/${username}`;
    }
  }
}
</script>
<style>
  .without-border {
    border: none !important;
  }
</style>
