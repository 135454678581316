<template>
  <div v-if="collaborations.length > 0">
    <b-card-title>{{ $t('briefcase_collabs.title') }}</b-card-title>
    <b-row>
      <b-col class="col-md-4 col-lg-3 col-xl-2 col-sm-4" v-for="collaboration in collaborations" :key="collaboration.uuid">
        <b-card
          :img-src="getImage(collaboration)"
          img-alt="Image"
          img-top
          style="object-fit: cover"
          class="cardImage"
        >
        <b-button class="buttonCard" variant="light" pill>
          <a :href="collaboration.post_url" target="_blank">{{ substr(collaboration.brand)  }}</a>
        </b-button>
        <div class="d-flex justify-content-between mt-n2">
          <a :href="collaboration.post_url" target="_blank">
            <b-button
              variant="none"
              class="link"
            >
              <feather-icon icon="Link2Icon" size="18" />
            </b-button
            >
          </a>
          <a :href="collaboration.post_url" target="_blank">
            <b-img :src="getIcon(collaboration.network)" class="iconSocialCard" />
          </a>
        </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BButton, BImg, BRow, BCol, BCardTitle } from 'bootstrap-vue'

import utils from "@/libs/utils"

export default {
  components: {
    BCard,
    BButton,
    BImg,
    BRow,
    BCol,
    BCardTitle
  },
  props: {
    collaborations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      backgroundDefault: require("@/assets/images/dashboard/b1b1b1.png"),
      netIcons: {
        tiktok: require("@/assets/images/icons/tiktok2.svg"),
        spotify: require("@/assets/images/icons/spotify2.svg"),
        pinterest: require("@/assets/images/icons/pinterest2.svg"),
        blog: require("@/assets/images/icons/blog2.svg"),
        snapchat: require("@/assets/images/icons/snapchat2.svg"),
        linkedin: require("@/assets/images/icons/linkedin2.svg"),
        youtube: require("@/assets/images/icons/youtube2.svg"),
        instagram: require("@/assets/images/icons/instagram2.svg"),
        twitter: require("@/assets/images/icons/twitter2.svg"),
        twitch: require("@/assets/images/icons/twitch2.svg"),
        facebook: require("@/assets/images/icons/facebook2.svg"),
      },
    }
  },
  methods: {
    getImage(collaboration) {
      if (collaboration.network.toLowerCase() === 'instagram') return `https://klear.com/api/2.1/HostedContent/instagram_post?url=${collaboration.post_url}`

      if (collaboration.network.toLowerCase() === 'youtube') {
        const video_id = utils.getParamUrl(collaboration.post_url, 'v');

        return `https://img.youtube.com/vi/${video_id}/hqdefault.jpg`
      }

      if (collaboration.source_media && collaboration.source_media !== '') return utils.getAssetUrl(collaboration.source_media)

      return this.backgroundDefault
    },
    substr(s) {
      return s.substr(0, 20);
    },
    getIcon(text) {
      for (const property in this.netIcons) {
        if (property === text) return this.netIcons[property];
      }
    },
  }
}
</script>

<style>
  .cardForm {
    height: 366px;
  }
  .iconSocial {
    width: 28px;
  }
  .select {
    display: block;
    width: 100% !important;
  }
  .cardImage img {
    width: 100%;
    object-fit: cover;
    height: 300px;
  }
  .buttonCard {
    margin-top: -150px;
    margin-left: 10px;
  }
  .link {
    padding: 0px !important;
    color: rgb(129,119,241);
  }
  .link:hover {
    color: rgb(129,119,241);
  }
  .iconSocialCard {
    width: 25px !important;
    height: auto !important;
  }
  .iconSocialCard:hover {
    transition: all 300ms;
    transform: scale(1.3);
    height: auto !important;
  }
</style>
