<template>
  <b-card>
    <b-card-title class="mb-1 text-center">
      {{this.title}}
    </b-card-title>

    <vue-apex-charts
      type="donut"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
  },
  props: {
    labels: {
      type: Array,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    chart_total: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chart_series: [],
      chartOptions: {
        chart_total: `${this.chart_total}`,
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: this.colors,
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '14px',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '12px',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    if (val !== undefined) {
                      if (parseInt(val) === undefined) return '0'
                      if (parseInt(val) >= 1000000) return `${Math.round(parseInt(val) / 1000000)}M`
                      if (parseInt(val) >= 1000) return `${Math.round(parseInt(val) / 1000)}K`
                      return val
                    }
                    // eslint-disable-next-line radix
                    return `0`
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: `${this.title}`,
                  formatter(w) {
                    return `${w.config.chart_total}`
                  },
                },
              },
            },
          },
        },
        labels: this.labels,
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '12px',
                      },
                      value: {
                        fontSize: '10px',
                      },
                      total: {
                        show: false,
                      },
                    },
                  },
                },
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    }
  },
  created() {
  },
}
</script>
