<template>
  <div>
    <b-card-title>{{ $t('briefcase_pricing.title') }}</b-card-title>
    <b-card class="full-card">
      <b-table 
        hover
        :items="prices"
        responsive
        show-empty
        :empty-text="$t('briefcase_pricing.no-pricing')"
        :fields="fields_table"
        class="position-relative"
      >
        <template #head(content_type)="data">
          <span>{{ $t(data.label) }} </span>
        </template>
        <template #head(price)="data">
          <span>{{ $t(data.label) }} </span>
        </template>
        <template #head(description)="data">
          <span>{{ $t(data.label) }} </span>
        </template>

        <template #cell(content_type)="data">
          <b-img :src="getIcon(data.item.network)" class="iconDropdown iconList " width="48"/> {{$t(data.item.content_type)}}
        </template>
        <template #cell(price)="data">
          {{utils.toCurrency(data.item.price)}}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BTable, BCard, BCardTitle, BImg } from 'bootstrap-vue'
import utils from '@/libs/utils'

export default {
  components: {
    BTable,
    BCard,
    BCardTitle,
    BImg
  },
  props: {
    prices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      utils,
      fields_table: [
        {
          key: 'content_type',
          label: 'briefcase_pricing.content_type',
          sortable: true
        },
        {
          key: 'price',
          label: 'briefcase_pricing.price',
          sortable: true
        },
        {
          key: 'description',
          label: 'briefcase_pricing.description',
          sortable: false
        }
      ],
      dropdownNetworks: [
        {
          value: "tiktok",
          network: "Tiktok",
          icon: require("@/assets/images/icons/tiktok2.svg"),
        },
        {
          value: "spotify",
          network: "Spotify",
          icon: require("@/assets/images/icons/spotify2.svg"),
        },
        {
          value: "snapchat",
          network: "Snapchat",
          icon: require("@/assets/images/icons/snapchat2.svg"),
        },
        {
          value: "blog",
          network: "Blog",
          icon: require("@/assets/images/icons/blog2.svg"),
        },
        {
          value: "pinterest",
          network: "Pinterest",
          icon: require("@/assets/images/icons/pinterest2.svg"),
        },

        {
          value: "linkedin",
          network: "LinkedIn",
          icon: require("@/assets/images/icons/linkedin2.svg"),
        },
        {
          value: "youtube",
          network: "Youtube",
          icon: require("@/assets/images/icons/youtube2.svg"),
        },
        {
          value: "instagram",
          network: "Instagram",
          icon: require("@/assets/images/icons/instagram2.svg"),
        },
        {
          value: "twitter",
          network: "Twitter",
          icon: require("@/assets/images/icons/twitter2.svg"),
        },
        {
          value: "twitch",
          network: "Twitch",
          icon: require("@/assets/images/icons/twitch2.svg"),
        },
        {
          value: "facebook",
          network: "Facebook",
          icon: require("@/assets/images/icons/facebook2.svg"),
        },
      ]
    }
  },
  methods: {
    getIcon(text) {
      let icon = "";
      this.dropdownNetworks.map((a) => {
        if (a.value === text) icon = a.icon;
      });
      return icon;
    },
  }
}
</script>

<style>
  .full-card .card-body {
    padding: 0;
  }
</style>
