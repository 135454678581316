<template>
  <b-modal 
    v-model="show"
    class="text-center"
    centered
    title="Selecciona la campaña a la que quieres agregar el creador"
    hide-footer
  >
    <validation-observer  #default="{ invalid }">
      <b-form>
        <div class="mb-3">
          <validation-provider rules="required">
            <b-form-select v-model="campaign" :options="campaigns">
              <b-form-select-option :value="null">{{$t('search.selectCampaign')}}</b-form-select-option>
            </b-form-select>
          </validation-provider>
        </div>

        <!-- Precios -->
        <b-row>
          <b-col
            cols="12"
            v-for="(content, index) in network_prices"
            :key="index"
          >
            <h4 class="text-center">
              <strong>Cuenta de <span style="text-transform: capitalize;">{{content.network}}</span></strong>
            </h4>
            <b-row>
              <b-col
                cols="12"
                v-for="(price, i) in content.prices"
                :key="i"
                class="mb-2"
              >
                <b-row class="align-items-center">
                  <b-col cols="3" class="text-center">
                    <div>{{$t(price.content_type)}}</div>
                  </b-col>

                  <b-col cols="5" class="text-center">
                    <b-form-spinbutton 
                      v-model="price.quantity"
                      min="0"
                      max="300"
                      @change="changeValue"
                    ></b-form-spinbutton>
                  </b-col>

                  <b-col cols="4" class="text-center">
                    <div>Precio: {{utils.toCurrency(price.price)}}</div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="text-center mt-2">
            <p style="font-size: 20px;">Total: <span style="color: #4b22f4;">{{utils.toCurrency(total)}}</span></p>
          </b-col>
        </b-row>

        <b-button class="mt-2 mb-1 float-right" :disabled="invalid" variant="primary" @click="addInfluencerToCampaign()">{{$t('search.add')}}</b-button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import { BModal, BRow, BCol, BFormSpinbutton, BFormSelect, BFormSelectOption, BButton, BForm } from 'bootstrap-vue'
import utils from '@/libs/utils'
import { ValidationProvider,ValidationObserver } from "vee-validate";
import { required } from "@validations";
import service_campaigns from '@/services/campaign'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    prices: {
      type: Array,
      required: true
    },
    campaigns: {
      type: Array,
      required: true
    },
    modalShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: String,
      default: () => ''
    },
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BModal,
    BRow,
    BCol,
    BFormSpinbutton,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      utils,
      required,
      show: this.modalShow,
      network_prices: [],
      total: 0,
      campaign: null,
      username: this.$route.params.alias,
      network: this.$route.params.network,
      followers: 0,
    }
  },
  created() {
    this.network_prices = this.formatPrices(this.prices);
  },
  methods: {
    formatPrices(prices) {
      const result = [];
      prices.forEach((value) => {
        const arr = Object.keys(value).map((key) => [key, value[key]]);
        const obj = {network: '', prices: []};
        arr.forEach(elemnet => {
          if (elemnet[0] === 'network') {
            obj.network = elemnet[1]
          } else {
            obj.prices.push(
              {
                content_type: elemnet[0],
                quantity: 0,
                subtotal: 0,
                price: elemnet[1]
              }
            )
          }
        })
        result.push(obj)
      })

      return result
    },
    changeValue() {
      this.total = 0;
      this.network_prices.forEach(content => {
        content.prices.forEach(price => {
          price.subtotal = (price.quantity * price.price)
          this.total += price.subtotal
        })
      })
    },
    addInfluencerToCampaign() {
      this.followers = this.headerData.followers;

      const form = new FormData()
      form.append('username', this.username);
      form.append('network', this.network);
      form.append('image', this.avatar);
      form.append('followers', this.followers);
      const contents = []
      service_campaigns.addInfluncerv3(this.campaign, form).then(response => {
        this.show = false;
        const influencef_uuid = response.uuid;
        this.network_prices.forEach(content => {
          const network = content.network;
          content.prices.forEach(price => {
            if (price.quantity > 0) {
              for (let i = 0; i < price.quantity; i++) {
                const type = price.content_type === 'live' ? 'streaming' : price.content_type;
                contents.push({
                  campaign_influencer: influencef_uuid,
                  network,
                  estimated_cost: price.price,
                  status: 'content_pending',
                  content_type: type
                })
              }
            }
          })

          service_campaigns.bulkAddContent(this.campaign, {contents})
            .then(() => {
            })

          service_campaigns.influencerAction(this.campaign, [influencef_uuid], 'approved')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('search.added'),
                  icon: 'BellIcon',
                  text: this.$t('search.hasBeenAdded'),
                  variant: 'success'
                }
              })
            })

          this.campaign = null;
        });
      });
    }
  }
}
</script>

